import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LotViewDetails from './LotViewDetails';
import {formatNumber} from "../utils/markdown";

const LOW_TIME_COLOR = '#FFDDDD'; // Цвет, если осталось < 1 часа
const DEFAULT_COLOR = '#F9FAFC'; // Обычный цвет

export default function LotItem({ lot }) {
    const [open, setOpen] = useState(false);

    // Функция для определения цвета фона
    const getBackgroundColor = (time) => {
        if (!time.includes('h')) {
            return LOW_TIME_COLOR; // Если в строке только 'm', значит меньше часа
        }
        return DEFAULT_COLOR;
    };

    return (
        <>
            <Card
                sx={{
                    backgroundColor: getBackgroundColor(lot.endDate),
                    borderRadius: 2,
                    boxShadow: 'none',
                    mb: 1,
                }}
            >
                <CardActionArea onClick={() => setOpen(true)} sx={{ height: 48, display: 'flex', alignItems: 'center', px: 1.5 }}>
                    {/* Иконка предмета */}
                    <Box
                        component="img"
                        src={`/img/items/${lot.icon}.png`}
                        alt={lot.name}
                        sx={{
                            width: 32,
                            height: 32,
                            borderRadius: 1,
                            objectFit: 'cover',
                            marginRight: 1.5,
                            flexShrink: 0,
                        }}
                    />

                    {/* Контейнер для текстовой информации */}
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, overflow: 'hidden' }}>
                        {/* Название предмета */}
                        <Typography
                            variant="caption"
                            sx={{
                                flexGrow: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {lot.name}
                        </Typography>
                    </Box>

                    {/* Цена с иконкой монеты */}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2, flexShrink: 0 }}>
                        <Box
                            component="img"
                            src="/img/gold.png"
                            alt="Coin"
                            sx={{ width: 16, height: 16, marginRight: 0.5 }}
                        />
                        <Typography
                            variant="caption"
                            sx={{
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {formatNumber(lot.price)}
                        </Typography>
                    </Box>
                </CardActionArea>
            </Card>

            {/* Всплывающее окно с деталями */}
            <LotViewDetails open={open} onClose={() => setOpen(false)} lot={lot} />
        </>
    );
}