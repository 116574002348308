import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {formatNumber} from "../utils/markdown";

export default function LotViewDetails({ open, onClose, lot }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            {/* Заголовок с кнопкой закрытия */}
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Lot #{lot.lotId}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {/* Основное содержимое */}
            <DialogContent>
                <Box
                    component="img"
                    src={`/img/items/${lot.icon}.png`}
                    alt={lot.name}
                    sx={{
                        width: 64,
                        height: 64,
                        borderRadius: 2,
                        display: 'block',
                        margin: '0 auto',
                        objectFit: 'cover',
                    }}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>
                 {lot.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {lot.properties || 'Unknown'}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Seller: {lot.seller || 'Unknown'}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Buyer: {lot.buyer || 'Unknown'}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Time left: {lot.endDate || 'Unknown'}
                </Typography>
            </DialogContent>

            {/* Цена и теги */}
            <DialogActions sx={{ justifyContent: 'space-between', px: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src="/img/gold.png"
                        alt="Coin"
                        sx={{ width: 16, height: 16, marginRight: 0.5 }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {formatNumber(lot.price)}
                    </Typography>
                </Box>
            </DialogActions>
        </Dialog>
    );
}